import React from 'react'
import { PDFViewer } from "@react-pdf/renderer";
import Bills from './Bills';
const BillsPreview = () => {
  return (
    <PDFViewer width="100%" height="1000">
    <Bills/>
  </PDFViewer>
  )
}

export default BillsPreview