import axiosInstance from "../config/API";
const API_ENDPOINT = '/invoice.php';

// Fetch all users
export const fetchInvoiceApi = async (searchText = "") => {
  const payload ={
    action : "listInvoice",
  }
  const response = await axiosInstance.post(API_ENDPOINT,payload);
  console.log("fetch invoice :" , response.data.body.invoices)
  return response.data.body.invoices;
};


// Add a new user
export const addInvoiceApi = async (invoiceData) => {
    console.log('invoicdataapi',invoiceData);
  const payload = {
    action: "createInvoice",
    customer_name: invoiceData.customerDetails.customer_name,
    state : invoiceData.customerDetails.state,
    city : invoiceData.customerDetails.city,
    mobile_number : invoiceData.customerDetails.mobile_number,
    reference_number : invoiceData.customerDetails.reference_number,
    agent_name : invoiceData.customerDetails.agent_name,
    transport_name : invoiceData.customerDetails.transport_name,
    products : invoiceData.products,
    overall_total : invoiceData.discount !== 0 ? invoiceData.totals.discountedTotal : invoiceData.totals.overallTotal,
    discount : invoiceData.discount,
    tax : invoiceData.totals.taxAmount,
    grand_total : invoiceData.totals.grandTotal,
    bill_created_by : "GURULAKSHMI FIREWORKS",
  }
  const response = await axiosInstance.post(API_ENDPOINT, payload);
  console.log("add invoice :" ,response.data)
  return response.data.body.invoices;  
};

// Update a user by ID
export const updateInvoiceApi = async (invoiceData) => {
  console.log(invoiceData,'inv');
  const payload = {
   action: "updateinvoice",
   invoice_id : invoiceData.id,
   customer_name: invoiceData.customerDetails.customer_name,
    state : invoiceData.customerDetails.state,
    city : invoiceData.customerDetails.city,
    mobile_number : invoiceData.customerDetails.mobile_number,
    reference_number : invoiceData.customerDetails.reference_number,
    agent_name : invoiceData.customerDetails.agent_name,
    transport_name : invoiceData.customerDetails.transport_name,
    products : invoiceData.products,
    overall_total : invoiceData.discount !== 0 ? invoiceData.totals.discountedTotal : invoiceData.totals.overallTotal,
    discount : invoiceData.discount,
    tax : invoiceData.totals.taxAmount,
    grand_total : invoiceData.totals.grandTotal,
    bill_created_by : "GURULAKSHMI FIREWORKS",
  }
  const response = await axiosInstance.post(`${API_ENDPOINT}`, payload);
  console.log('update response',response.data);
  return response.data.body.id;  // Corrected response structure
};

// Delete a user by ID
export const deleteInvoiceApi = async (id) => {
  const payload = {
    action: "deleteinvoice",
    invoice_id : id
  }
  const response = await axiosInstance.post(`${API_ENDPOINT}`,payload);
  return id;  // Return the user ID for successful deletion
};
