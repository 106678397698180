import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./components/Components.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import routes from "./routes/Routes";
import Login from "./view/Login";
import Layout from "./routes/LayOut";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import Billpreview from "./pdf/BillPreview";
import PrivateRoute from "./routes/PrivateRoute";
import BillsPreview from "./pdf/BillsPreview";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/billpreview" element={<Billpreview />} />
          <Route path="/bills" element={<BillsPreview />} />
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <PrivateRoute>
                  <Layout>{route.element}</Layout>
                </PrivateRoute>
              }
            />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
