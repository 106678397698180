import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import fontBold from "./assets/fonts/MYRIADPRO-BOLD.OTF";
import fontRegular from "./assets/fonts/MYRIADPRO-REGULAR.OTF";
import logoImage from "../assets/images/storelogo.png";
// Registering the fonts
Font.register({
  family: "MyriadPro",
  fonts: [
    { src: fontRegular, fontStyle: "normal", fontWeight: "normal" },
    { src: fontBold, fontStyle: "normal", fontWeight: "bold" },
  ],
});

// Define styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 0,
    position: "relative",
  },
  section: {
    flexGrow: 1,
    border: 1,
    margin: 20,
  },
  pageMargin_20: {
    margin: 20,
  },
  fwBold: {
    fontFamily: "MyriadPro",
    fontWeight: "bold",
  },
  fontSize12: {
    fontSize: 12,
  },
  textRegular: {
    fontFamily: "MyriadPro",
    fontWeight: "normal",
  },
  mtb: {
    marginTop: 2.2,
    marginBottom: 2.2,
  },
  logo: {
    width: "90px",
    textAlign: "center",
    margin: "0 auto",
  },
  w15: {
    width: "15%",
  },
  w20: {
    width: "20%",
  },
  w30: {
    width: "30%",
  },
  w50: {
    width: "50%",
  },
  w70: {
    width: "70%",
  },
  w100: {
    width: "100%",
  },
  textCenter: {
    textAlign: "center",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
  // billing head start
  billTo: {
    width: "80%",
  },
  invoiceDetails: {
    width: "30%",
  },
  table: {
    display: "table",
  },
  invoiceTablerow: {
    flexDirection: "row",
    marginBottom: 9,
  },
  invoiceTablecellHead: {
    width: "45%",
  },
  invoiceTablecell: {
    width: "55%",
  },
  // billing head start
  productTablerow: {
    flexDirection: "row",
  },
  productDetails: {
    flexDirection: "row",
  },
  pad5: {
    padding: 5,
  },
  borderRight: {
    borderRight: 1,
  },
  borderBottom: {
    borderBottom: 1,
  },
  borderTop: {
    borderTop: 1,
  },

  // footer start
  footer: {
    position: "absolute",
    bottom: 0,
    fontSize: 12,
  },
  padv2: {
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  // footer end
});

// Define the component
const Bills = () => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={[styles.pageMargin]}>
              <Image src={logoImage} style={styles.logo} />
            </View>
            <View style={[styles.textCenter,styles.borderBottom,styles.pad5]}>
                <Text style={styles.fwBold}>SRI GURULAKSHMI FIREWORKS INDUSTRIES</Text>
                <Text style={styles.fontSize12}>Vijayakarisalkulam, Vembakottai(TK), Virudhunagar(DT) - 626131</Text>
            </View>
          </View>
          {/* bill head start */}
          <View style={[styles.flex, styles.pageMargin_20, styles.fontSize12]}>
            <View style={styles.billTo}>
              <Text style={styles.fwBold}>Bill To:</Text>
              <View>
                <Text style={styles.textRegular}>Mahavilvam Fireworks</Text>
                <Text style={styles.textRegular}>
                  No:12, Near RS Nagar,{"\n"}Sattur-Sivakasi Main Road,Sivakasi.
                </Text>
                <Text>Agent Name : Arun </Text>
                <Text>Transport : Mettur Transport</Text>
              </View>
            </View>
            <View style={[styles.invoiceDetails, styles.table]}>
              <View style={styles.invoiceTablerow}>
                <Text style={[styles.invoiceTablecellHead, styles.fwBold]}>
                  Invoice No
                </Text>
                <Text style={[styles.invoiceTablecell]}>:ZT-1293</Text>
              </View>
              <View style={styles.invoiceTablerow}>
                <Text style={[styles.invoiceTablecellHead, styles.fwBold]}>
                  Date
                </Text>
                <Text style={[styles.invoiceTablecell]}>:21/11/2024</Text>
              </View>
            </View>
          </View>
          {/* bill head end */}
          {/* product table start */}
          <View
            style={[
              styles.productDetails,
              styles.pageMargin,
              styles.fontSize12,
              styles.borderBottom,
              styles.borderTop,
            ]}
          >
            <Text
              style={[
                styles.fwBold,
                styles.pad5,
                styles.w15,
                styles.borderRight,
              ]}
            >
              S No
            </Text>
            <Text
              style={[
                styles.fwBold,
                styles.w70,
                styles.pad5,
                styles.borderRight,
              ]}
            >
              Product Details
            </Text>
            <Text
              style={[
                styles.fwBold,
                styles.w30,
                styles.pad5,
                styles.borderRight,
              ]}
            >
              Quantity
            </Text>
            <Text
              style={[
                styles.fwBold,
                styles.w30,
                styles.pad5,
                styles.borderRight,
              ]}
            >
            Content
            </Text>
            <Text
              style={[
                styles.fwBold,
                styles.w30,
                styles.pad5,
                styles.borderRight,
              ]}
            >
              Unit Price
            </Text>
            <Text
              style={[
                styles.fwBold,
                styles.w30,
                styles.pad5,
                styles.textCenter,
              ]}
            >
              Price
            </Text>
          </View>
          <View
            style={[
              styles.productDetails,
              styles.pageMargin,
              styles.fontSize12,
              styles.borderBottom,
            ]}
          >
            <Text style={[styles.pad5, styles.w15, styles.borderRight]}>1</Text>
            <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
              Ground Chakkar Big
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              1(Case)
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
           300pkt
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              300
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.textCenter]}>
              300
            </Text>
          </View>
          <View
            style={[
              styles.productDetails,
              styles.pageMargin,
              styles.fontSize12,
              styles.borderBottom,
            ]}
          >
            <Text style={[styles.pad5, styles.w15, styles.borderRight]}>1</Text>
            <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
              Ground Chakkar Big
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              1(Case)
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
           300pkt
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              300
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.textCenter]}>
              300
            </Text>
          </View>
          <View
            style={[
              styles.productDetails,
              styles.pageMargin,
              styles.fontSize12,
              styles.borderBottom,
            ]}
          >
            <Text style={[styles.pad5, styles.w15, styles.borderRight]}>1</Text>
            <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
              Ground Chakkar Big
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              1(Case)
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
           300pkt
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              300
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.textCenter]}>
              300
            </Text>
          </View>
          <View
            style={[
              styles.productDetails,
              styles.pageMargin,
              styles.fontSize12,
              styles.borderBottom,
            ]}
          >
            <Text style={[styles.pad5, styles.w15, styles.borderRight]}>1</Text>
            <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
              Ground Chakkar Big
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              1(Case)
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
           300pkt
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              300
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.textCenter]}>
              300
            </Text>
          </View>
          <View
            style={[
              styles.productDetails,
              styles.pageMargin,
              styles.fontSize12,
              styles.borderBottom,
            ]}
          >
            <Text style={[styles.pad5, styles.w15, styles.borderRight]}>1</Text>
            <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
              Ground Chakkar Big
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              1(Case)
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
           300pkt
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              300
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.textCenter]}>
              300
            </Text>
          </View>
          <View
            style={[
              styles.productDetails,
              styles.pageMargin,
              styles.fontSize12,
              styles.borderBottom,
            ]}
          >
            <Text style={[styles.pad5, styles.w15, styles.borderRight]}>1</Text>
            <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
              Ground Chakkar Big
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              1(Case)
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
           300pkt
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              300
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.textCenter]}>
              300
            </Text>
          </View>
          <View
            style={[
              styles.productDetails,
              styles.pageMargin,
              styles.fontSize12,
              styles.borderBottom,
            ]}
          >
            <Text style={[styles.pad5, styles.w15, styles.borderRight]}>1</Text>
            <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
              Ground Chakkar Big
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              1(Case)
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
           300pkt
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              300
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.textCenter]}>
              300
            </Text>
          </View>
          <View
            style={[
              styles.productDetails,
              styles.pageMargin,
              styles.fontSize12,
              styles.borderBottom,
            ]}
          >
            <Text style={[styles.pad5, styles.w15, styles.borderRight]}>1</Text>
            <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
              Ground Chakkar Big
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              1(Case)
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
           300pkt
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.borderRight]}>
              300
            </Text>
            <Text style={[styles.w30, styles.pad5, styles.textCenter]}>
              300
            </Text>
          </View>
          {/* product table end */}
          {/* footer start */}
          <View style={[styles.footer]}>
            <View style={ styles.flex}>
              <View style={[styles.w30]}>
                <View style={[styles.borderTop]}>
                  <Text
                    style={[
                      styles.w100,
                      styles.borderRight,
                      styles.fwBold,
                      styles.pad5,
                    ]}
                  >
                    Bank Details
                  </Text>
                  <Text
                    style={[
                      styles.w100,
                      styles.borderRight,
                      styles.fwBold,
                      styles.pad5,
                    ]}
                  >
                    BANK OF MAHARASHTRA
                  </Text>
                  <Text
                    style={[
                      styles.w100,
                      styles.borderRight,
                      styles.fwBold,
                      styles.pad5,
                    ]}
                  >
                    A/c No : 60164352801
                  </Text>
                  <Text
                    style={[
                      styles.w100,
                      styles.borderRight,
                      styles.fwBold,
                      styles.pad5,
                    ]}
                  >
                    IFSCode : MAHB0001198
                  </Text>
                </View>
              </View>
              <View style={[styles.w70]}>
                <View style={[styles.productDetails, styles.borderTop]}>
                  <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
                    Overall Total
                  </Text>
                  <Text style={[styles.pad5, styles.w20, styles.textCenter]}>
                    5000
                  </Text>
                </View>
                <View style={[styles.productDetails, styles.borderTop]}>
                  <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
                    Discount
                  </Text>
                  <Text style={[styles.pad5, styles.w10, styles.borderRight]}>
                    12%
                  </Text>
                  <Text style={[styles.pad5, styles.w10]}>3000</Text>
                </View>
                <View style={[styles.productDetails, styles.borderTop]}>
                  <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
                    Tax
                  </Text>
                  <Text style={[styles.pad5, styles.w10, styles.borderRight]}>
                    12%
                  </Text>
                  <Text style={[styles.pad5, styles.w10]}>3000</Text>
                </View>
                <View style={[styles.productDetails, styles.borderTop]}>
                  <Text style={[styles.w70, styles.pad5, styles.borderRight]}>
                    Grand Total
                  </Text>
                  <Text style={[styles.pad5, styles.w20, styles.textCenter]}>
                    15000
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.productDetails, styles.borderTop]}>
              <Text style={[styles.w50, styles.padv2]}>Bill Created By :</Text>
              <Text style={[styles.w50, styles.textCenter, styles.padv2]}>
                Thanks For Your Purchasing ...!
              </Text>
            </View>
          </View>
          {/* footer end */}
        </View>
      </Page>
    </Document>
  );
};

export default Bills;
