import React from "react";
import { useLocation } from "react-router-dom";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  PDFViewer,
} from "@react-pdf/renderer";
import Bill from "./Bill";

const BillPreview = () => {
  const location = useLocation();
  const { invoice } = location.state || {}; // Retrieve invoice data from state

  if (!invoice) {
    return <div>Error: No invoice data available.</div>;
  }

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  return (
    <div>
      {!isMobile ? (
        <PDFViewer width="100%" height="1000">
          <Bill invoice={invoice} />
        </PDFViewer>
      ) : (
        <PDFDownloadLink
          document={<Bill invoice={invoice} />}
          fileName="invoice.pdf"
          className="downloadpdf "
        >
          {({ loading }) => (loading ? "Loading document..." : "Download PDF")}
        </PDFDownloadLink>
      )}
    </div>
  );
};

export default BillPreview;
