import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
 fetchInvoiceApi,
 addInvoiceApi,
 updateInvoiceApi,
 deleteInvoiceApi
} from "../services/invoiceService";

// Fetch all users
export const fetchInvoice = createAsyncThunk("invoice/fetchInvoice", async () => {
  const response = await fetchInvoiceApi();
  console.log('response',response);
  return response;
});

// Add new user
export const addInvoice = createAsyncThunk("invoice/addInvoice", async (invoiceData) => {
  console.log("invoiceData:" ,invoiceData)
  const response = await addInvoiceApi(invoiceData);
  console.log("API Response on Add invoice:", response);
  return response;
});



export const updateInvoice = createAsyncThunk(
  "invoice/updateInvoice",
  async (updatedInvoice, { rejectWithValue }) => {
    try {
      console.log('updateInvoice',updatedInvoice);
      const response = await updateInvoiceApi(updatedInvoice);
      return updatedInvoice;
    } catch (error) {
      console.error("Error updating invoice:", error);
      return rejectWithValue(error.response?.data || "Failed to update invoice");
    }
  }
);


// Delete user by ID
export const deleteInvoice = createAsyncThunk(
  "invoice/deleteInvoice",
  async (id) => {
    const response = await deleteInvoiceApi(id);
    return {
      id
    };
  }
);
const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    invoice: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInvoice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.invoice = action.payload;
      })
      .addCase(fetchInvoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addInvoice.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log("action.payload:", action.payload);
        state.invoice.push(action.payload[0]);  // Add the user to the users array
      })
      .addCase(addInvoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        state.status = "succeeded"; 
        console.log(action.payload);
       
        state.invoice = state.invoice.filter((invoice) => invoice.id !== action.payload.id);
      })
      .addCase(deleteInvoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.status = "succeeded";
      
        // Ensure state.invoice is an array
        if (!Array.isArray(state.invoice)) {
          console.error("State.invoice is not an array");
          return;
        }

        console.log(action.payload);
      
        const index = state.invoice.findIndex((user) => user.id === action.payload);
      
        if (index !== -1) {
          // Replace the updated invoice immutably
          state.invoice = [
            ...state.invoice.slice(0, index),
            action.payload,
            ...state.invoice.slice(index + 1),
          ];
        } else {
          console.warn("Invoice not found in state for update", action.payload);
        }
      })      
      .addCase(updateInvoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default invoiceSlice.reducer;
