import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Table } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import { DropDown, TextInputForm } from "../../components/Forms";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Buttons } from "../../components/Buttons";
import Footer from "../../components/Footer";
import Dialog from "../../components/Dialog";
import { fetchproduct } from "../../slices/productSlice";
import { HiOutlineDownload } from "react-icons/hi";
import NotifyData from "../../components/NotifyData";
import { addInvoice, updateInvoice } from "../../slices/invoiceSlice";
import { useLocation } from "react-router-dom";

const InvoiceCreations = () => {
  const dispatch = useDispatch();

  const { product } = useSelector((state) => state.product); // Redux state for products
  const navigate = useNavigate();
  const { state } = useLocation(); // Access state passed from navigate
  const { invoice } = state || {}; // Extract the invoice or default to undefined
  const [taxAmount, setTaxAmount] = useState("");
  console.log(invoice);

  console.log('invoice', invoice);

  const [customerDetails, setCustomerDetails] = useState({});
  console.log("customerDetails", customerDetails);
  const [products, setProducts] = useState([
    {
      id: 1, // Add an ID to each product row
      productName: "",
      quantity: "",
      unitPrice: "",
      amount: 0,
      unitType: "",
      subunitType: "",
      isSubunit: false,
      subcount: 1
    },
  ]);



  console.log('products', products);
  const [discount, setDiscount] = useState(3.5);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    dispatch(fetchproduct()); // Fetch product data
  }, [dispatch]);

  useEffect(() => {
    if (invoice) {
      // Group customer-related fields into customerDetails
      const customerDetails = {
        customer_name: invoice.customer_name || "",
        state: invoice.state || "",
        city: invoice.city || "",
        mobile_number: invoice.mobile_number || "",
        reference_number: invoice.reference_number || "",
        agent_name: invoice.agent_name || "",
        transport_name: invoice.transport_name || "",
      };

      setCustomerDetails(customerDetails);

      // Parse products if stored as JSON string
      let parsedProducts = [];
      try {
        parsedProducts = Array.isArray(invoice.products)
          ? invoice.products
          : JSON.parse(invoice.products || "[]");
      } catch (e) {
        console.error("Failed to parse products:", e);
      }

      setProducts(parsedProducts);
      setDiscount(invoice.discount || 0);
      setTaxAmount(invoice.tax || 0);
    }
  }, [invoice]);



  const handleInputChange = (index, field, value) => {
    const updatedProducts = [...products];
    const productRow = updatedProducts[index];

    // eslint-disable-next-line default-case
    switch (field) {
      case "productName":
        const selectedProduct = product.find((p) => p.product_name === value);
        if (selectedProduct) {
          productRow.productName = selectedProduct.product_name;
          //productRow.unitPrice = parseFloat(selectedProduct.unit_price) || "";
          productRow.unitType = selectedProduct.Unit_type;
          productRow.subunitType = selectedProduct.SubUnit_type;
          productRow.subcount = parseInt(selectedProduct.Sub_count) || 1;
          productRow.amount = productRow.quantity * productRow.subcount * productRow.unitPrice;
        }
        break;

      case "quantity":
        const newQuantity = parseInt(value) || "";
        productRow.quantity = newQuantity;

        if (productRow.isSubunit) {

          productRow.amount = newQuantity * productRow.unitPrice;
        } else {
          productRow.amount = newQuantity * productRow.subcount * productRow.unitPrice;
        }
        break;

      case "isSubunit":
        productRow.isSubunit = value === "subunit";


        if (productRow.isSubunit) {
          productRow.amount = productRow.quantity * (productRow.unitPrice);

        } else {
          productRow.amount = productRow.quantity * productRow.subcount * productRow.unitPrice;

        }
        break;

      case "unitPrice":
        productRow.unitPrice = value; // Keep it as a string for now
        const parsedPrice = parseFloat(value) || 0;

        if (productRow.isSubunit) {
          productRow.amount = productRow.quantity * parsedPrice;
        } else {
          productRow.amount = productRow.quantity * productRow.subcount * parsedPrice;
        }
        break;


      case "subcount":
        const subcountValue = parseInt(value) || 1;
        productRow.subcount = subcountValue;

        if (productRow.isSubunit) {
          productRow.unitPrice = parseFloat(productRow.unitPrice) || "";
          productRow.amount = productRow.quantity * (productRow.unitPrice / subcountValue);
        }
        break;
    }

    console.log("Updated products", updatedProducts);
    setProducts(updatedProducts);
  };
  const addNewRow = () => {
    const lastProduct = products[products.length - 1];

    console.log("Last product row:", lastProduct);


    if (!lastProduct.productName || lastProduct.quantity <= 0 || lastProduct.unitPrice <= 0) {
      NotifyData("Please fill out all fields in the current row before adding a new one.", "warn");
      return;
    }

    setProducts((prev) => [
      ...prev,
      {
        id: prev.length ? prev[prev.length - 1].id + 1 : 1, // Generate a unique ID
        productName: "",
        quantity: "",
        unitPrice: "",
        amount: 0,
        unitType: "",
        subunitType: "",
        isSubunit: false,
        subcount: 1,
      },
    ]);
  };


  const deleteRow = (id) => {
    if (products.length > 1) {
      setProducts((prev) => prev.filter((product) => product.id !== id));
    }
  };


  const calculateTotals = () => {
    const overallTotal = products.reduce((sum, product) => sum + product.amount, 0);
    const discountedTotal = overallTotal - (overallTotal * discount) / 100; // Subtotal after discount
    const grandTotal = Number(discountedTotal) + Number(taxAmount); // Add tax amount to discounted total

    return {
      overallTotal: Number(overallTotal),
      discountedTotal: Number(discountedTotal),
      taxAmount: Number(taxAmount),
      grandTotal: Number(grandTotal)
    };
  };




  const totals = calculateTotals();

  const handleCloseForm = () => {
    setShowConfirmDialog(true);
  };
  const handleConfirmClose = (confirm) => {
    setShowConfirmDialog(false);
    if (confirm) {
      console.log("Form closed");
      navigate(-1);
    }
  };
  const handleSubmit = () => {
    // Validate if required fields are filled
    if (!customerDetails["customer_name"] || products.some(p => p.quantity <= 0 || !p.productName)) {
      NotifyData("Please complete all required fields before submitting the form.", "warn");
      return;
    }
    const id = invoice?.id || null;
    const invoicePayload = {
      id,
      customerDetails,
      products,
      discount: parseFloat(discount) || 0,
      totals: calculateTotals(),
    };

    console.log("Submitting Invoice Payload:", invoicePayload);

    // Simulate server request / dispatch Redux action
    try {
      if (invoice) {
        // Dispatch update action
        dispatch(updateInvoice(invoicePayload)).unwrap();
        NotifyData("Invoice updated successfully!", "success");
      } else {
        // Dispatch create action
        dispatch(addInvoice(invoicePayload)).unwrap();
        NotifyData("Invoice saved successfully!", "success");
      }
      navigate(-1); // Navigate back after submission
    } catch (error) {
      NotifyData("Failed to save the invoice. Please try again.", "error");
    }
  };
  return (
    <div id="main">
      <Container fluid>
        <Row>
          <Col lg="12">
            <PageTitle
              PageTitle={invoice ? "Update Invoice" : "Create Invoice"}
              showButton={true}
              OnClick={handleCloseForm}
            />
          </Col>
          <Col lg="12" className="py-3">
            <div className="content-box">
              <Row>
                {[
                  { label: "Customer Name", value: "customer_name" },
                  { label: "State", value: "state" },
                  { label: "City", value: "city" },
                  { label: "Mobile Number", value: "mobile_number" },
                  { label: "Reference Number", value: "reference_number" },
                  { label: "Agent Name", value: "agent_name" },
                  { label: "Transport Name", value: "transport_name" }
                ].map((item, index) => (
                  <Col lg="4" md="6" xs="12" className="py-3" key={index}>
                    <TextInputForm
                      textlabel={item.label}
                      value={customerDetails[item.value] || ""} // Bind value from customerDetails
                      onChange={(e) =>
                        setCustomerDetails({
                          ...customerDetails,
                          [item.value]: e.target.value, // Update the corresponding field
                        })
                      }
                    />
                  </Col>
                ))}
              </Row>


            </div>
          </Col>
          <Col xs="12">
            <div className="content-box overflow-hy">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    {products.some((row) => row.unitType || row.subunitType) && <th>Unit/Subunit</th>}
                    <th>Quantity</th>
                    <th>Content</th>
                    <th>Unit Price</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <DropDown
                          options={product.map((item) => ({
                            label: item.product_name,
                            value: item.product_name,
                          }))}
                          value={row.productName}
                          onChange={(e) => handleInputChange(index, "productName", e.target.value)}
                        />
                      </td>
                      {row.unitType && row.subunitType !== "" && (
                        <>
                          <td>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name={`unitType-${index}`}
                                  value="unit"
                                  checked={!row.isSubunit}
                                  onChange={() => handleInputChange(index, "isSubunit", "unit")}
                                />
                                {row.unitType}
                              </label>

                              <label>
                                <input
                                  type="radio"
                                  name={`unitType-${index}`}
                                  value="subunit"
                                  checked={row.isSubunit}
                                  onChange={() => handleInputChange(index, "isSubunit", "subunit")}
                                />
                                {row.subunitType}
                              </label>
                            </div>
                          </td>
                        </>
                      )}
                      <td>
                        <TextInputForm
                          value={row.quantity}
                          onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
                        />
                      </td>
                      {row.isSubunit !== true ? (
                        <>
                          <td>
                            {row.subcount + " " + row.subunitType}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            {row.quantity + " " + row.subunitType}
                          </td>
                        </>

                      )}

                      <td>
                        <TextInputForm
                          value={row.unitPrice} // Bind the raw string value
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            // Regex to allow numbers with optional decimals (but not just ".")
                            if (/^\d*\.?\d*$/.test(inputValue)) {
                              handleInputChange(index, "unitPrice", inputValue); // Pass valid input
                            }
                          }}
                          placeholder="Enter unit price"
                        />

                      </td>


                      <td>{row.amount.toFixed(2)}</td>
                      <td>
                        <Buttons label={<MdOutlineDeleteOutline />} classname="icon-only delete" OnClick={() => deleteRow(row.id)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Buttons label="Add New Row" classname="crud-btn" OnClick={addNewRow} />

            </div>
          </Col>
          <Col lg="12" className="py-3">
            <div className="content-box">
              <Table>
                <tbody>
                  <tr>
                    <td colSpan="5">Overall Total</td>
                    <td>Rs.{totals.overallTotal.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Less (%)</td>
                    <td colSpan="4">
                      {/* Input for Percentage */}
                      <TextInputForm
                        value={discount}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only valid numeric inputs, including decimals
                          if (/^\d*\.?\d*$/.test(value)) {
                            setDiscount(value); // Keep the value as a string for user input
                          }
                        }}
                        placeholder="Enter discount %" // Optional: User-friendly placeholder
                      />
                    </td>
                    <td>
                      {/* Display the calculated discount amount */}
                      Rs.{discount ? ((parseFloat(totals.overallTotal) * parseFloat(discount)) / 100).toFixed(2) : "0.00"}
                    </td>
                  </tr>


                  <tr>
                    <td colSpan="5">Subtotal</td>
                    <td>Rs.{totals.discountedTotal.toFixed(2)}</td> {/* Subtotal after discount */}
                  </tr>
                  <tr>
                    <td>Tax (Amount)</td>
                    <td colSpan="4">
                      <TextInputForm
                        value={taxAmount}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value) || "";
                          setTaxAmount(value); // Update the tax amount directly
                        }}
                      />
                    </td>
                    <td>Rs.{taxAmount}</td> {/* Show the entered tax amount */}
                  </tr>
                  <tr>
                    <td colSpan="5">Grand Total</td>
                    <td>Rs.{totals.grandTotal.toFixed(2)}</td> {/* Subtotal + Tax */}
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col lg="12">
            <Footer

              LabelOne={
                <>
                  <span className="mx-1">
                    <HiOutlineDownload size="20" />
                  </span>
                  {invoice ? "Update" : "Save"}
                </>
              }
              LabelOneClick={
                handleSubmit
              }
              LabelTwo={
                <>
                  Cancel
                </>
              }
              LabelTwoClick={
                handleCloseForm
              }
            />
          </Col>
        </Row>
      </Container>
      <>
        <Dialog
          DialogTitle="Do You Want Close ?"
          isVisible={showConfirmDialog}
          onConfirm={handleConfirmClose}
          onCancel={() => handleConfirmClose(false)}
        />
      </>
      <></>
    </div>
  );
};

export default InvoiceCreations;
