import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { TextInputForm } from "../components/Forms";
import { Buttons } from "../components/Buttons";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import FormHandle from "../hooks/FormHandlehelper";
import { useDispatch } from "react-redux";
import { loginUser } from "../services/LoginService";
import { useNavigate } from "react-router-dom";
import NotifyData from "../components/NotifyData"
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = { email: "", password: "" };
  const [formData, handleChange] = FormHandle(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    
    // Input validation
    if (!formData.email || !formData.password) {
      NotifyData("Please enter email and password.", "error");
      return;
    }
  
    try {
      // Dispatch login action with the form data
      const response = await dispatch(loginUser(formData));
  
      if (response?.meta?.requestStatus === 'fulfilled') {
        NotifyData("Login Success", "success");
        navigate("/invoices");
      } else {
        // Handle unsuccessful login response
        NotifyData(response?.payload || "Invalid email or password", "error");
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Unexpected error during login:", error);
      NotifyData("Something went wrong. Please try again later.", "error");
    }
  };
  

  return (
    <>
      <div className="pad_100">
        <Container>
          <Row className="justify-content-center">
            <Col lg="8" md="8" xs="12">
              <div className="login-box">
                <Row className="justify-content-center">
                  <Col lg="6" md="12" xs="12">
                    <img
                      src={require("../assets/images/store.png")}
                      className="img-fluid"
                      alt="gurulakshmi fireworks"
                    />
                  </Col>
                  <Col lg="6" md="12" xs="12" className="align-self-center">
                    <Row>
                      <Col lg="12" md="12" xs="12">
                        <div>
                          <h2 className="bold">Welcome Back </h2>
                          <p className="regular">
                            Enter Your Mobile No and Password to access your
                            account
                          </p>
                        </div>
                      </Col>
                      <Col lg="12" md="12" xs="12" className="py-3">
                        <TextInputForm
                          textlabel="Mobile Number"
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col lg="12" md="12" xs="12" className="py-3">
                        <TextInputForm
                          textlabel="Password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          classname="form-control-padright"
                          type={showPassword ? "text" : "password"}
                          suffix_icon={
                            <>
                              {showPassword ? (
                                <VscEyeClosed
                                  onClick={togglePasswordVisibility}
                                />
                              ) : (
                                <VscEye onClick={togglePasswordVisibility} />
                              )}
                            </>
                          }
                        />
                      </Col>
                      <Col lg="12" md="12" xs="12" className="py-3">
                        <Buttons
                        label="Login"
                          classname="login-btn"
                          OnClick={handleLogin}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
