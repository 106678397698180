import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import { TextInputForm } from '../../components/Forms';
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from '../../components/TableUI';
import { ActionButton, Buttons } from '../../components/Buttons';
import { MdOutlineDelete } from "react-icons/md";
import { LiaEditSolid } from "react-icons/lia";
import CustomModal from '../../components/Modal';
import ProductsCreations from './ProductsCreations';
import Pagnation from '../../components/Pagnation';
import { fetchproduct,addProduct,updateProduct,deleteProduct } from '../../slices/productSlice';
import NotifyData from '../../components/NotifyData';

const Products = () => {
  const dispatch = useDispatch();
  const { product } = useSelector((state) => state.product); // Redux state for products
  

  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(fetchproduct()); // Fetch products when the component loads
  }, [dispatch]);
  console.log('products',product);
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  console.log(formData);

  const handleCreate = () => {
    setEditMode(false);
    setFormData({});
    handleOpen();
  };

  const handleEdit = (product) => {
    setEditMode(true);
    setSelectedProduct(product);
    setFormData(product);
    handleOpen();
  };

  const handleDelete = async (id) => {
    await dispatch(deleteProduct(id)).unwrap();
    NotifyData("Product Deleted Successfully", "success");
  };

  const handleSubmit = async () => {
    if (editMode) {
      await dispatch(updateProduct({ ...selectedProduct, ...formData }));
      NotifyData("Product Updated Successfully", "success");
    } else {
      await dispatch(addProduct(formData)).unwrap();
      NotifyData("Product Created Successfully", "success");
    }
    handleClose();
    setFormData({});
  };

  const filteredProducts = product.filter((product) =>
    String(product.product_name)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  

  const tableHeaders = ['No',"Product Name"];
  const tableData = filteredProducts.map((product, index) => ({
    values: [
      index + 1,
      product.product_name,
      <ActionButton
        options={[
          { label: 'Edit', icon: <LiaEditSolid />, onClick: () => handleEdit(product) },
          { label: 'Delete', icon: <MdOutlineDelete />, onClick: () => handleDelete(product.id) },
        ]}
        label={<HiOutlineDotsVertical />}
      />,
    ],
  }));

  return (
    <div id='main'>
      <Container fluid>
        <Row>
          <Col lg="6" className="py-3 align-self-center">
            <PageTitle PageTitle="Products" showButton={false} />
          </Col>
          <Col lg="6" md="12" xs="12" className='py-3 text-end'>
            <Buttons label={<>Add Products</>} classname="crud-btn" OnClick={handleOpen}/>
          </Col>
          <Col lg="3" className='align-self-center'>
            <TextInputForm prefix_icon={<FaMagnifyingGlass />} PlaceHolder="Search" className="form-control-padleft" />
          </Col>
          <Col lg="12" xs="12">
            <TableUI headers={tableHeaders} body={tableData} className="table-end"/>
          </Col>
          <Col lg="12" xs="12" className='text-end'>
            <Pagnation/>
          </Col>
        </Row>
      </Container>

      <CustomModal
        show={show}
        setShow={setShow}
        pageTitle={editMode ? "Edit Product" : "Add Product"}
        showButton={true}
        submitButton={true}
       
        label={editMode ? "Update" : "Submit"}
        CancelLabel="Cancel"
        BodyComponent={
          <ProductsCreations
            formData={formData}
            setFormData={setFormData}
           
          />
        }
        OnClick={handleSubmit}
      />
    </div>
  );
};

export default Products;
